// Import the functions you need from the SDKs you need
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore';

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// firebase.initializeApp({
//   apiKey: "AIzaSyDtT1xbLzFDZtOXadP4kfcccu3JfNu5LzM",
//   authDomain: "spotify-6f0e9.firebaseapp.com",
//   projectId: "spotify-6f0e9",
//   storageBucket: "spotify-6f0e9.appspot.com",
//   messagingSenderId: "907612668634",
//   appId: "1:907612668634:web:bdfad04104f8b8e6389be3"
// });

// let db = firebase.firestore()


// export default {
//   firebase, db
// }

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyDtT1xbLzFDZtOXadP4kfcccu3JfNu5LzM",
  authDomain: "spotify-6f0e9.firebaseapp.com",
  projectId: "spotify-6f0e9",
  storageBucket: "spotify-6f0e9.appspot.com",
  messagingSenderId: "907612668634",
  appId: "1:907612668634:web:bdfad04104f8b8e6389be3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export { db };