import './App.css';
import { useEffect, useState } from 'react';
import { artistsIds } from './APIdata';
import { db } from './firebase/index';
import { collection, getDocs, doc, setDoc, serverTimestamp } from "firebase/firestore";
import CountUp from 'react-countup';
import frame from "./frame3.png";
import SpotifyPlayer from 'react-spotify-web-playback';

const AUTH_URL =
  "https://accounts.spotify.com/authorize?client_id=6a5912650f614d698a9634061982d889&response_type=token&redirect_uri=https://spotguess.eliottgandiolle.fr&scope=streaming%20user-read-email%20user-read-private%20user-library-read%20user-library-modify%20user-read-playback-state%20user-modify-playback-state&show_dialog=true";

function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function randomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function formatDate(d) {
  return d.toISOString().substring(0, 10);
}

const getHashFromLocation = () => {
  return window.location.hash
    .substring(1)
    .split("&")
    .reduce((initial, item) => {
      if (item) {
        const parts = item.split("=");
        initial[parts[0]] = decodeURIComponent(parts[1]);
      }
      return initial;
    }, {});
}

function App() {
  const [artistData, setArtistsData] = useState(null);
  const [last10ids, setLast10ids] = useState([]);
  const [roundNumber, setRoundNumber] = useState(0);
  const [currentPicks, setCurrentPicks] = useState([]);
  const [reveal, setReveal] = useState(null);
  const token = getHashFromLocation().access_token;

  const fetchData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      if (response.status !== 400) {
        return null;
      }
      return await response.json();
    } catch (error) {
      return null;
    }
  }

  const isDataOld = (lastUpdate) => {
    const date = new Date(lastUpdate.seconds * 1000);
    return date.getTime() <= Date.now() - 31 * 24 * 60 * 60 * 1000;
  }


  const fetchingNewData = async () => {
    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Host': 'spotify23.p.rapidapi.com',
        'X-RapidAPI-Key': process.env.REACT_APP_RAPIDAPI_KEY
      }
    };

    const totalData = await Promise.all(artistsIds.map(async artist => {
      const data = await fetchData(`https://spotify23.p.rapidapi.com/artist_overview/?id=${artist}`, options);
      if (data) {
        const artistData = {
          id: data.data.artist.id,
          name: data.data.artist.profile.name,
          images: data.data.artist.visuals,
          topSongs: data.data.artist.discography.topTracks.items,
          stats: data.data.artist.stats,
          lastUpdate: serverTimestamp()
        };
        await setDoc(doc(db, "artists", artistData.id), artistData);
        return artistData;
      }
      return null;
    }));

    const filteredData = totalData.filter(Boolean);
    window.localStorage.setItem("artists", JSON.stringify(filteredData));
    setArtistsData(filteredData);
  }

  const fetchingDataFromFirebase = async () => {
    const totalData = [];
    const querySnapshot = await getDocs(collection(db, "artists"));
    querySnapshot.forEach((doc) => {
      totalData.push(doc.data());
    });
    return totalData;
  }

  const initializeData = async () => {
    let localData = JSON.parse(window.localStorage.getItem('artists'));
    if (localData && !isDataOld(localData[0].lastUpdate)) {
      setArtistsData(localData);
      return;
    }

    const firebaseData = await fetchingDataFromFirebase();
    if (firebaseData.length > 0 && !isDataOld(firebaseData[0].lastUpdate)) {
      window.localStorage.setItem("artists", JSON.stringify(firebaseData));
      setArtistsData(firebaseData);
      return;
    }

    const newData = await fetchingNewData();
    if (!newData && firebaseData.length > 0) {
      window.localStorage.setItem("artists", JSON.stringify(firebaseData));
      setArtistsData(firebaseData);
    }
  }

  useEffect(() => {
    initializeData();
  }, []);

  const pickAnId = () => {
    let random = randomInt(artistData.length);
    while (last10ids.includes(random)) {
      random = randomInt(artistData.length);
    }
    const updatedIds = last10ids.length === 10 ? [...last10ids.slice(1), random] : [...last10ids, random];
    setLast10ids(updatedIds);
    return random;
  }

  const changeRound = () => {
    const newPicks = roundNumber === 0
      ? [artistData[pickAnId()], artistData[pickAnId()]]
      : [currentPicks[1], artistData[pickAnId()]];
    setCurrentPicks(newPicks);
    const newRoundNumber = roundNumber + 1;
    const bestScore = window.localStorage.getItem("bestScore") || 1;
    if (newRoundNumber > bestScore) {
      window.localStorage.setItem("bestScore", newRoundNumber);
    }
    setRoundNumber(newRoundNumber);
  }

  const checkAnswer = (plus) => {
    setReveal({ answer: true });
    const isCorrect = currentPicks[1].stats.monthlyListeners === currentPicks[0].stats.monthlyListeners ||
      (plus && currentPicks[1].stats.monthlyListeners > currentPicks[0].stats.monthlyListeners) ||
      (!plus && currentPicks[1].stats.monthlyListeners < currentPicks[0].stats.monthlyListeners);
    setTimeout(() => {
      if (isCorrect) {
        changeRound();
      } else {
        setRoundNumber(0);
      }
      setReveal(null);
    }, 4000);
  }

  return (
    <div className={`container ${roundNumber === 0 ? "fullHeight" : ""}`} style={{ paddingBottom: token ? 48 : 0 }}>
      {artistData ? (
        roundNumber === 0 ? (
          <div className="homepage" style={{ position: "relative", width: 300, height: 300 }}>
            {!token && (
              <a href={AUTH_URL}>
                <img className="enhance" src={frame} alt="Spotify Authentication" />
              </a>
            )}
            <p className="playButton" onClick={changeRound}> Play </p>
          </div>
        ) : (
          <>
            {token && (
              <div style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 1 }}>
                <SpotifyPlayer
                  magnifySliderOnHover={true}
                  styles={{
                    activeColor: '#fff',
                    bgColor: '#333',
                    color: '#fff',
                    loaderColor: '#fff',
                    sliderColor: '#1cb954',
                    trackArtistColor: '#ccc',
                    trackNameColor: '#fff',
                  }}
                  token={token}
                  uris={currentPicks[1]?.topSongs.sort((a, b) => b.track.playcount - a.track.playcount).map(e => e.track.uri)}
                  autoPlay={true}
                />
              </div>
            )}
            <div className="contendant">
              <p className="date"> Last MAJ: {formatDate(new Date(currentPicks[0].lastUpdate.seconds * 1000))} </p>
              <div className="image" style={{ backgroundImage: `url(${currentPicks[0].images?.avatarImage?.sources[0]?.url})` }}>
                <div className="content">
                  <p className="name"> {currentPicks[0].name} </p>
                  <p className="score" style={{ color: reveal ? reveal.answer ? "green" : "red" : "white" }}>
                    {formatNumber(currentPicks[0].stats.monthlyListeners)}
                  </p>
                  <p style={{ margin: 2 }}> monthly listeners </p>
                </div>
              </div>
            </div>
            <div className="midContent">
              <p style={{ margin: 0 }}> Score <span style={{ opacity: 0.6 }}>(best: {window.localStorage.getItem("bestScore")})</span></p>
              <p className="currentScore"> {roundNumber}<span className="flamme">🔥</span> </p>
              <p className="qText"> Who has the most <span style={{ fontWeight: "bold" }}> monthly listeners ?</span> </p>
              <p className="button buttonP" onClick={() => checkAnswer(false)}> {currentPicks[0].name} </p>
              <p className="qText2"> or </p>
              <p className="button buttonM" onClick={() => checkAnswer(true)}> {currentPicks[1].name} </p>
            </div>
            <div className="contendant">
              <div className="image" style={{ backgroundImage: `url(${currentPicks[1].images?.avatarImage?.sources[0]?.url})` }}>
                <div className="content">
                  <p className="name"> {currentPicks[1].name} </p>
                  {reveal && <CountUp delay={0} duration={3} useEasing={true} easing={(x, t, b, c, d) => {
                    if ((t /= d / 2) < 1) return c / 2 * t * t * t + b;
                    return c / 2 * ((t -= 2) * t * t + 2) + b;
                  }} separator=" " className="score" end={currentPicks[1].stats.monthlyListeners} />}
                </div>
              </div>
            </div>
          </>
        )
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default App;
