export const artistsIds = [
    "4tZwfgrHOc3mvqYlEYSvVi",
    "3q7HBObVc0L8jNeTe5Gofh",
    "3AA28KZvwAUcZuOKwyblJQ",
    "4npEfmQ6YuiwW1GpUmaq3F",
    "1ZwdS5xdxEREPySFridCfh",
    "0fA0VVWsXO9YnASrzqfmYu",
    "1HBjj22wzbscIZ9sEb5dyf",
    "5y2Xq6xcjJb2jVM54GHK3t",
    "757aE44tKEUQEqRuT6GnEB",
    "7gZfnEnfiaHzxARJ2LeXrf",
    "43ZHCT0cAZBISjO8DG9PnE",
    "4NHQUGzhtTLFvgF5SZesLK",
    "4sTQVOfp9vEMCemLw50sbu",
    "6DPYiyq5kWVQS4RGwxzPC7",
    "6LqNN22kT3074XbTVUrhzX",
    "0YC192cP3KPCRWx8zr8MfZ",
    "3IW7ScrzXmPvZhB27hmfgy",
    "0epOFNiUfyON9EYx7Tpr6V",
    "74ASZWbe4lXaubB36ztrGX",
    "25uiPmTg16RbhZWAqwLBy5",
    "1IueXOQyABrMOprrzwQJWN",
    "4Z8W4fKeB5YxbusRsdQVPb",
    "51Blml2LZPmy7TTiAg47vQ",
    "2jku7tDXc6XoB6MO2hFuqg",
    "5he5w2lnU9x7JFhnwcekXX",
    "0oSGxfWSnnOXhD2fKuz2Gy",

    "3TVXtAsR1Inumwj472S9r4",

    "6eUKZXaKkcviH0Ku9w2n3V",

    "4q3ewBCX7sLwd24euuV69X",

    "1Xyo4u8uXC1ZmMpatF05PJ",

    "66CXWjxzNUsdJxJ2JdwvnR",

    "1uNFoZAHBGtllmzznpCI3s",

    "06HL4z0CvFAxyc27GXpf02",

    "7dGJo4pcD2V6oG8kP0tJRR",

    "246dkjvS1zLTtiykXe5h60",

    "3Nrfpe0tUJi4K4DXYWgMUX",

    "1vyhD5VmyZ7KMfW5gqLgo5",

    "5K4W6rqBFWDnAN6FQUkS6x",

    "6qqNVTkY8uBg9cP3Jd7DAH",

    "4MCBfE4596Uoi2O4DtmEMz",

    "4gzpq5DPGxSnKTe4SA8HAU",

    "15UsOTVnJzReFVN1VCnxy4",

    "6M2wZ9GZgrQXHCFfjv46we",

    "1i8SpTcr7yvPOmcqrbnVXY",

    "53XhwfbYqKCa1cC15pYq2q",

    "6LuN9FCkKOj5PcnpouEgny",

    "0Y5tJX1MQlPlqiwlOH1tJY",

    "5pKCCKE2ajJHZ9KAiaK11H",

    "04gDigrS5kc9YWfZHwBETP",

    "7n2wHs1TKAczGzO7Dd2rGr",

    "1Cs0zKBU1kc0i8ypK3B9ai",

    "0du5cEVh5yTK9QJze8zA0C",

    "4VMYDCV2IEDYJArk749S6m",

    "7CajNmpbOovFoOoasH2HaY",

    "2wY79sveU1sp5g7SokKOiI",

    "1dfeR4HaWDbWqFHLkxsg1d",

    "2YZyLoL8N0Wb9xBt1NhZWg",

    "69GGBxA162lTqCwzJG5jLp",

    "4AK6F7OLvEQ5QYCBNiQWHq",

    "1RyvyyTE3xzB2ZywiAwp0i",

    "7bXgB6jMjp9ATFy66eO08Z",

    "6vWDO969PvNqNYHIOW5v0m",

    "0hCNtLu0JehylgoiP8L4Gh",

    "1HY2Jd0NmPuamShAr6KMms",

    "2R21vXR83lH98kGeO99Y66",

    "6l3HvQ5sa6mXTsMTB19rO5",

    "26VFTg2z8YR0cCuwLzESi2",

    "4dpARuHxo51G3z768sgnrY",

    "0C8ZW7ezQVs4URX5aX7Kqx",

    "3WrFJ7ztbogyGnTHbHJFl2",

    "5WUlDfRSoLAfcVSX1WnrxN",

    "1r4hJ1h58CWwUQe3MxPuau",

    "3YQKmKGau1PzlVlkL1iodx",

    "64KEffDW9EtZ1y2vBYgq8T",

    "4O15NlyKLIASxsJ0PrXPfz",

    "6XyY86QOPPrYVGvF9ch6wz",

    "6jJ0s89eD6GaHleKKya26X",

    "23fqKkggKUBHNkbKtXEls4",

    "1vCWHaC5f2uS3yhpwWbIA6",

    "329e4yvIujISKGKz1BZZbO",

    "00FQb4jTyendYWaN8pK0wa",

    "4nDoRrQiYLoBzwC5BhVJzF",

    "1mcTU81TzQhprhouKaTkpq",

    "07YZf4WDAMNwqr4jfgOZ8y",

    "0L8ExT028jH3ddEcZwqJJ5",

    "7Ln80lUS6He07XvHI8qqHH",

    "6S2OmqARrzebs0tKUEyXyp",

    "5cj0lLjcoR7YOSnhnX0Po5",

    "0EmeFodog0BfCgMzAIvKQp",

    "6KImCVD70vtIoJWnq6nGn3",

    "790FomKkXshlbRYZFtlgla",

    "1SupJlEpv7RS2tPNRaHViT",

    "5Pwc4xIPtQLFEnJriah9YJ",

    "5YGY8feqx7naU7z4HrwZM6",

    "3fMbdgg4jU18AjLCKBhRSm",

    "60d24wfXkVzDSfLS6hyCjZ",

    "0TnOYISbd1XYRBk9myaseg",

    "6VuMaDnrHyPL1p4EHjYLi7",

    "07YUOmWljBTXwIseAUd9TW",

    "20JZFwl6HVl6yg8a4H3ZqK",

    "02kJSzxNuaWGqwubyUba0Z",

    "4LLpKhyESsyAXpc4laK94U",

    "4kYSro6naA4h99UJvo89HB",

    "4r63FhuTkUYltbVAg5TQnk",

    "77ziqFxp5gaInVrF2lj4ht",

    "5f7VJjfbwm532GiveGC0ZK",

    "738wLrAtLtCtFOLvQBXOXp",

    "55Aa2cqylxrFIXC767Z865",

    "1URnnhqYAYcrqrcwql10ft",

    "0X2BH1fck6amBIoJhDVmmJ",

    "50co4Is1HCEo8bhOyUWKpn",

    "137W8MRPWKqSmrBGDBFSop",

    "0eDvMgVFoNV3TpwtrVCoTj",

    "7iK8PXO48WeuP03g8YR51W",

    "2ye2Wgw4gimLv2eAKyk1NB",

    "4UXqAaa6dQYAk18Lv7PEgX",

    "5fMUXHkw8R8eOP2RNVYEZX",

    "7jVv8c5Fj3E9VhNjxT4snq",

    "2kCcBybjl3SAtIcwdWpUe3",

    "6oMuImdp5ZcFhWP0ESe6mG",

    "4xRYI6VqpkE3UwrDrAZL8L",

    "7vk5e3vY1uw9plTHJAMwjN",

    "5LHRHt1k9lMyONurDHEdrp",

    "0hEurMDQu99nJRq8pTxO14",

    "1KCSPY1glIKqW2TotWuXOR",

    "1McMsnEElThX1knmY4oliG",

    "711MCceyCBcFnzjGY4Q7Un",

    "3e7awlrlDSwF3iM0WBjGMp",

    "540vIaP2JwjQb9dm3aArA4",

    "5ZsFI1h6hIdQRw2ti0hz81",

    "6Xgp2XMz1fhVYe7i6yNAax",

    "4IWBUUAFIplrNtaOHcJPRM",

    "3JhNCzhSMTxs9WLGJJxWOY",

    "5JZ7CnR6gTvEMKX4g70Amv",

    "3PhoLpVuITZKcymswpck5b",

    "46SHBwWsqBkxI7EeeBEQG7",

    "13ubrt8QOOCPljQ2FL1Kca",

    "2hlmm7s2ICUX0LVIhVFlZQ",

    "2qxJFvFYMEDqd7ui6kSAcq",

    "6fOMl44jA4Sp5b9PpYCkzz",

    "31W5EY0aAly4Qieq6OFu6I",

    "5Rl15oVamLq7FbSb0NNBNy",

    "2h93pZq0e7k5yf4dywlkpM",

    "3t5xRXzsuZmMDkQzgOX35S",

    "7wlFDEWiM5OoIAt8RSli8b",

    "2QsynagSdAqZj3U9HgDzjD",

    "2o5jDhtHVPhrJdv3cEQ99Z",

    "7oPftvlwr6VrsViSDV7fJY",

    "4V8LLVI7PbaPR0K2TGSxFF",

    "64M6ah0SkkRsnPGtGiRAbb",

    "31TPClRtHm23RisEBtV3X7",

    "0iEtIxbK0KxaSlF7G42ZOp",

    "5lwmRuXgjX8xIwlnauTZIP",

    "2nszmSgqreHSdJA3zWPyrW",

    "0QHgL1lAIqAw0HtD7YldmP",

    "6MDME20pz9RveH9rEXvrOM",

    "1zNqDE7qDGCsyzJwohVaoX",

    "1Xylc3o4UrD53lo9CvFvVg",

    "7tYKF4w9nC0nq9CsPZTHyP",

    "4GNC7GD6oZMSxPGyXy4MNB",

    "6TIYQ3jFPwQSRmorSezPxX",

    "1yxSLGMDHlW21z4YXirZDS",

    "1VPmR4DJC1PlOtd0IADAO0",

    "7qG3b048QCHVRO5Pv1T5lw",

    "1GxkXlMwML1oSg5eLPiAz3",

    "41MozSoPIsD1dJM0CLPjZF",

    "6olE6TJLqED3rqDCT0FyPh",

    "0k17h0D3J5VfsdmQ1iZtE9",

    "3nFkdlSjzX9mRTtwJOzDYB",

    "22bE4uQ6baNwSHPVcDxLCe",

    "0jnsk9HBra6NMjO2oANoPY",

    "7c0XG5cIJTrrAgEC3ULPiq",

    "1yR65psqiazQpeM79CcGh8",

    "4V8Sr092TqfHkfAA5fXXqG",

    "08GQAI4eElDnROBrJRGE0X",

    "26dSoYclwsYLMAKD3tpOr4",

]

export const names = [
    'Drake',
    'Ed Sheeran',
    'Bad Bunny',
    'The Weeknd',
    'Ariana Grande',
    'Justin Bieber',
    'Taylor Swift',
    'Eminem',
    'Post Malone',
    'BTS',
    'J Balvin',
    'Kanye West',
    'Billie Eilish',
    'Juice WRLD',
    'Coldplay',
    'XXXTENTACION',
    'Dua Lipa',
    'Ozuna',
    'Imagine Dragons',
    'Khalid',
    'Travis Scott',
    'Rihanna',
    'Maroon 5',
    'Shawn Mendes',
    'David Guetta',
    'Bruno Mars',
    'Daddy Yankee',
    'Calvin Harris',
    'Sam Smith',
    'Queen',
    'Kendrick Lamar',
    'The Chainsmokers',
    'One Direction',
    'Future',
    'Chris Brown',
    'Beyoncé',
    'Nicki Minaj',
    'Lady Gaga',
    'Anuel AA',
    'J. Cole',
    'Halsey',
    'Adele',
    'Selena Gomez',
    'The Beatles',
    'Sia',
    'Maluma',
    'Twenty One Pilots',
    'Marshmello',
    'Lil Uzi Vert',
    'Linkin Park',
    'Katy Perry',
    'Kygo',
    'Avicii',
    'Farruko',
    'Lana Del Rey',
    'Camila Cabello',
    'Rauw Alejandro',
    'Jason Derulo',
    'Red Hot Chili Peppers',
    'Arctic Monkeys',
    'Demi Lovato',
    'Doja Cat',
    'Shakira',
    'Harry Styles',
    'KAROL G',
    'Nicky Jam',
    'OneRepublic',
    'Miley Cyrus',
    'Michael Jackson',
    'Martin Garrix',
    'Pitbull',
    'Charlie Puth',
    'Sebastian Yatra',
    'Panic! At The Disco',
    'G-Eazy',
    'Mac Miller',
    'Cardi B',
    'DaBaby',
    'Sech',
    'Lil Baby',
    'Major Lazer',
    'Lil Wayne',
    '21 Savage',
    'Ellie Goulding',
    'Young Thug',
    'Wiz Khalifa',
    'Pop Smoke',
    'Myke Towers',
    'Metallica',
    'Fall Out Boy',
    'Diplo',
    'Lil Nas X',
    'Lil Peep',
    'Migos',
    'Logic',
    'Alan Walker',
    'Tyga',
    'John Mayer',
    'P!nk',
    'Olivia Rodrigo',
    'AC/DC',
    'Little Mix',
    'DJ Snake',
    'ZAYN',
    'Trippie Redd',
    'James Arthur',
    'Macklemore',
    'Lauv',
    'Elton John',
    'Kodak Black',
    'A$AP Rocky',
    'Gunna',
    'Zedd',
    'NF',
    'A Boogie Wit da Hoodie',
    '5 Seconds of Summer',
    'Frank Ocean',
    'Robin Schulz',
    'YoungBoy Never Broke Again',
    'Bob Marley &amp; The Wailers',
    'Tiësto',
    'Green Day',
    'Tyler, The Creator',
    'Bebe Rexha',
    'Justin Timberlake',
    'Metro Boomin',
    'Romeo Santos',
    'Luis Miguel',
    'DJ Khaled',
    'Clean Bandit',
    'Anne-Marie',
    'Zara Larsson',
    'SZA',
    'Lewis Capaldi',
    'Machine Gun Kelly',
    'Black Eyed Peas',
    '$uicideboy$',
    'Enrique Iglesias',
    'Michael Bublé',
    'BLACKPINK',
    'Nirvana',
    'Pink Floyd',
    'JAY-Z',
    'The Rolling Stones',
    'Flo Rida',
    'Ty Dolla $ign',
    'Marília Mendonça',
    'Luis Fonsi',
    'Fleetwood Mac',
    'Britney Spears',
]



      // let artistsIds = [];
      // for (let artistName of names) {
      //   //search an artist information by name on spotify api
      //   const response = await fetch(`https://api.spotify.com/v1/search?q=${artistName}&type=artist&limit=1`, {
      //     headers: {
      //       'Authorization': 'Bearer BQCwr0uU3Hj4CPjDgKQKBsBgNDLO2URG_rPdIP_lUwRfr2WoZRhouZxX8Gsv05Sx7QvxI4KWr0xFUmailBleOuSEnCFjdy4y1y8BsYG6B8UlD448u7MdyOrANSis427EIMvMYIvxJjQvUJ1PlVXMNqpcB9_A9N1XdBYyyWhUdsFf8EmIPUg',
      //       'Content-Type': 'application/json'
      //     }
      //   });
      //   const data = await response.json();
      //   // console.log(data);
      //   // return;
      //   artistsIds.push(data.artists.items["0"].id);
      //   console.log(artistsIds)
      // }
      // setIds(artistsIds);